import { TBaseLayer, useLayers, useSource } from '@geovelo-frontends/commons';
import { cellToBoundary, gridDisk, gridPathCells, latLngToCell } from 'h3-js';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../context';

import { Map } from '!maplibre-gl';

export type THeatmapData = {
  cells: string[];
  isInBiggerRing: {
    [key: string]: true;
  };
};

const sourceId = 'simplified-traces';
const layerId = 'simplified-traces';
const h3SourceId = 'h3-cells';
const h3LayerId = 'h3-cells';

function useHeatmap({
  displayH3,
  displayTraces,
  map,
  mapInitialized,
  baseLayer,
  heatmapData,
  traces,
}: {
  baseLayer: TBaseLayer;
  displayH3: boolean;
  displayTraces: boolean;
  heatmapData: THeatmapData | undefined;
  map: Map | undefined;
  mapInitialized: boolean;
  traces?: GeoJSON.FeatureCollection<GeoJSON.LineString>;
}) {
  const [sourcesInitialized, setSourcesInitialized] = useState(false);
  const {
    user: { current: currentUser },
  } = useContext(AppContext);
  const {
    addGeoJSONSource: addTracesSource,
    updateGeoJSONSource: updateTracesSource,
    clearGeoJSONSource: clearTracesSource,
  } = useSource(map, sourceId);
  const {
    addGeoJSONSource: addH3Source,
    updateGeoJSONSource: updateH3Source,
    clearGeoJSONSource: clearH3Source,
  } = useSource(map, h3SourceId);
  const { addLineLayer } = useLayers(map);

  useEffect(() => {
    if (mapInitialized) {
      if (displayH3) {
        addH3Source();
        map?.addLayer(
          {
            id: h3LayerId,
            type: 'fill',
            source: h3SourceId,
            layout: {
              visibility: currentUser?.isGeovelo || !displayTraces ? 'visible' : 'none',
            },
            paint: {
              'fill-color': ['get', 'color'],
              'fill-opacity': ['get', 'opacity'],
              'fill-outline-color': ['get', 'color'],
            },
          },
          'labels',
        );
      }

      if (displayTraces) {
        addTracesSource();
        addLineLayer(
          layerId,
          sourceId,
          {
            'line-join': 'round',
            'line-cap': 'round',
            visibility: currentUser?.isGeovelo ? 'none' : 'visible',
          },
          {
            'line-color': ['get', 'color'],
            'line-opacity': ['get', 'opacity'],
            'line-width': 3,
          },
        );
      }

      setSourcesInitialized(true);
    }
  }, [mapInitialized]);

  useEffect(() => {
    if (sourcesInitialized && displayH3 && heatmapData) {
      const { cells, isInBiggerRing } = heatmapData;

      updateH3Source({
        type: 'FeatureCollection',
        features:
          cells.map((index) => ({
            type: 'Feature',
            geometry: { type: 'Polygon', coordinates: [cellToBoundary(index, true)] },
            properties: {
              color:
                baseLayer === 'dark'
                  ? isInBiggerRing[index]
                    ? 'rgb(234, 142, 182)'
                    : 'rgb(142, 234, 194)'
                  : isInBiggerRing[index]
                    ? 'rgb(165, 73, 116)'
                    : 'rgb(73, 116, 165)',
              opacity: baseLayer === 'dark' ? 0.2 : 0.3,
            },
          })) || [],
      });
    }

    return () => {
      if (sourcesInitialized && displayH3) clearH3Source();
    };
  }, [sourcesInitialized, heatmapData, baseLayer]);

  useEffect(() => {
    if (sourcesInitialized && displayTraces && traces) {
      updateTracesSource({
        type: 'FeatureCollection',
        features:
          traces.features?.map(({ ...otherProps }) => ({
            ...otherProps,
            properties: {
              ...otherProps.properties,
              color: baseLayer === 'dark' ? 'rgb(142, 234, 194)' : 'rgb(73, 116, 165)',
              opacity: baseLayer === 'dark' ? 0.4 : 0.5,
            },
          })) || [],
      });
    }

    return () => {
      if (sourcesInitialized && displayTraces) clearTracesSource();
    };
  }, [sourcesInitialized, traces, baseLayer]);
}

export function getCells(traces: GeoJSON.FeatureCollection<GeoJSON.LineString>) {
  const hasCell: { [key: string]: boolean } = {};

  traces.features?.forEach(({ geometry }) => {
    const coordinatesCells: string[] = [];
    geometry?.coordinates.forEach(([lng, lat], index) => {
      const coordinatesCell = latLngToCell(lat, lng, 7);
      coordinatesCells.push(coordinatesCell);

      if (index > 0) {
        const segmentCells = gridPathCells(coordinatesCells[index - 1], coordinatesCell);
        segmentCells.forEach((segementCell) => {
          hasCell[segementCell] = true;
        });
      }
    });
  });

  return Object.keys(hasCell);
}

export function getBiggerRing(cells: string[]) {
  const hasCell = cells.reduce<{ [key: string]: boolean }>((res, key) => {
    res[key] = true;
    return res;
  }, {});
  const ignoredCells: { [key: string]: boolean } = {};

  let isInBiggerRing: { [key: string]: true } = {};
  let ringSize = 1;

  while (true) {
    let ringFound = false;

    for (const cell of cells) {
      if (!ignoredCells[cell]) {
        const ringCells = gridDisk(cell, ringSize);
        if (ringCells.every((ringCell) => hasCell[ringCell])) {
          isInBiggerRing = ringCells.reduce<{ [key: string]: true }>((res, ringCell) => {
            res[ringCell] = true;
            return res;
          }, {});
          ringFound = true;
          break;
        } else {
          ignoredCells[cell] = true;
        }
      }
    }

    if (ringFound) ++ringSize;
    else break;
  }

  return { isInBiggerRing };
}

export default useHeatmap;
