import {
  CommunityItem,
  Geogroup,
  GeogroupService,
  SearchIcon,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Close } from '@mui/icons-material';
import { Box, Collapse, IconButton, Pagination, Tooltip, Typography } from '@mui/material';
import { Link, navigate } from 'gatsby';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/button';
import Dialog from '../../components/dialog';
import { FireWheelIcon } from '../../components/icons';
import Seo from '../../components/seo';
import Banner from '../../components/slices/banner';
import { maxWidth } from '../../components/slices/consts';
import { AppContext } from '../../context';
import environment from '../../environment';
import { TPageProps } from '../../page-props';

import EmployeeCard from './employee-card';
import SuggestedCommunities from './suggested-communities';
import UserCommunities from './user-communities';

const pageSize = 100;

function CommunitiesPage(props: TPageProps) {
  const [initialized, setInitialized] = useState(false);
  const [discoverCompaniesOfferClosed, closeDiscoverCompaniesOffer] = useState(() => {
    try {
      if (typeof window !== 'undefined')
        return localStorage.getItem('discover_companies_offer_closed') === 'true';
    } catch {
      console.error('localStorage access is denied');
    }

    return false;
  });
  const [geogroups, setGeogroups] = useState<Geogroup[]>();
  const [pagesCount, setPagesCount] = useState<number>();
  const [page, setPage] = useState(1);
  const {
    user: { current: currentUser, geogroups: userGeogroups, employee },
    actions: { getUserGeogroups },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized && currentUser && !userGeogroups) getUserGeogroups();
  }, [initialized, currentUser]);

  useEffect(() => {
    if (initialized) getGeogroups();

    return () => {
      cancelPromises();
      setGeogroups(undefined);
    };
  }, [initialized, page]);

  async function getGeogroups() {
    try {
      const { count, geogroups } = await cancellablePromise(
        GeogroupService.getGeogroups({
          pageSize,
          page,
          types: ['city'],
        }),
      );

      setGeogroups(geogroups);
      setPagesCount(Math.ceil(count / pageSize));
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('geovelo.communities.server_error_list'), { variant: 'error' });
      }
    }
  }

  const title = t('geovelo.navigation.communities');
  const description = t('geovelo.pages_descriptions.communities');

  return (
    <>
      <Seo description={description} title={title} {...props} />
      <Banner
        small
        data={{
          id: '',
          slice_type: 'banner',
          primary: {
            fullscreen_background: true,
            background_color_start: '#326AC2',
            dark_background: true,
            baseline: { text: title },
            subtitle: { text: description },
          },
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap={{ xs: 6, lg: 8 }}
        paddingBottom={{ xs: 6, lg: 8 }}
        paddingTop={{ xs: 6, lg: 8 }}
      >
        <Box alignItems="center" alignSelf="stretch" display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            gap={5}
            maxWidth="100%"
            paddingX={{ xs: 4, md: 8, lg: 16 }}
            width={maxWidth}
          >
            <Box display="flex" flexDirection="column" gap={2}>
              {employee === null ? (
                <Collapse in={!discoverCompaniesOfferClosed}>
                  <Box
                    bgcolor="#FFF5D0"
                    borderRadius={4}
                    display="flex"
                    flexDirection={{ xs: 'column-reverse', md: 'row' }}
                    gap={2}
                    padding={2}
                  >
                    <Box
                      alignItems={{ xs: 'flex-start', md: 'center' }}
                      display="flex"
                      flexDirection={{ xs: 'column', md: 'row' }}
                      flexGrow={1}
                      gap={2}
                    >
                      <FireWheelIcon
                        sx={{
                          display: { xs: 'none', md: 'block' },
                          flexShrink: 0,
                          height: 48,
                          width: 48,
                        }}
                      />
                      <Box>
                        <Typography fontWeight={600}>
                          {t('geovelo.communities.discover_companies_offer.title')}
                        </Typography>
                        <Typography>
                          {t('geovelo.communities.discover_companies_offer.description')}
                        </Typography>
                      </Box>
                      <Box flexGrow={1} />
                      <Button
                        color="primary"
                        component="a"
                        href={environment.enterpriseShowcaseUrl}
                        rel="noreferrer"
                        sx={{
                          flexShrink: 0,
                          textDecoration: 'underline',
                          '&:hover': { textDecoration: 'underline' },
                        }}
                        target="_blank"
                      >
                        {t('geovelo.communities.discover_companies_offer.actions.discover')}
                      </Button>
                    </Box>
                    <Box alignSelf={{ xs: 'flex-end', md: 'flex-start' }} flexShrink={0}>
                      <Tooltip title={t('commons.actions.close')}>
                        <IconButton
                          onClick={() => {
                            try {
                              localStorage.setItem('discover_companies_offer_closed', 'true');
                            } catch {
                              console.error('localStorage access is denied');
                            }
                            closeDiscoverCompaniesOffer(true);
                          }}
                          size="small"
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Collapse>
              ) : (
                employee && <EmployeeCard />
              )}
            </Box>
            {currentUser !== null ? (
              <>
                <UserCommunities />
                <SuggestedCommunities />
              </>
            ) : (
              <>
                <Box alignSelf="flex-end">
                  <Button
                    color="primary"
                    component={Link}
                    size="medium"
                    startIcon={<SearchIcon />}
                    to={`/${currentLanguage}/communities/search`}
                    variant="outlined"
                  >
                    {t('commons.communities.actions.search')}
                  </Button>
                </Box>
                <Box
                  alignItems="stretch"
                  display="flex"
                  flexWrap="wrap"
                  gap={2}
                  justifyContent="center"
                >
                  {geogroups
                    ? geogroups.map((geogroup) => (
                        <CommunityItem
                          Button={Button}
                          currentUser={null}
                          Dialog={Dialog}
                          geogroup={geogroup}
                          handleTryToJoinAsGuest={() =>
                            navigate(`/${currentLanguage}/sign-in`, {
                              state: { prevPath: `/${currentLanguage}/communities` },
                            })
                          }
                          key={geogroup.id}
                        />
                      ))
                    : new Array(10).fill(null).map((_, index) => <CommunityItem key={index} />)}
                </Box>
                <Box alignSelf="center">
                  <Pagination
                    count={pagesCount}
                    onChange={(_, page) => setPage(page)}
                    page={page}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default CommunitiesPage;
