import {
  IPhoto,
  IRideMedia,
  IRideStep,
  Place,
  Ride,
  TBackendCyclingProfile,
  TBackendPublicationStatus,
  TBackendRideArea,
  TBackendRideDifficulty,
  TBackendRideMediaType,
  TBackendRidePublicKind,
  areas,
  backendCyclingProfiles,
  difficulties,
  mediaTypes,
  publicKinds,
  publicationStatuses,
} from '@geovelo-frontends/commons';
import moment from 'moment';

import { RideData, RideDataMedias, RideDataSteps } from '../../graphql-types';
import environment from '../environment';

import { parsePhoto } from './photo';

const { backendUrl: _backendUrl } = environment;

export function parseRide(
  {
    id,
    creator,
    title,
    description,
    distance,
    route_duration,
    duration,
    difficulty,
    public_kind,
    area_wide,
    themes,
    icon,
    geo_point_a,
    geo_point_a_title,
    geo_point_b,
    geo_point_b_title,
    geo_point_center,
    geometry_condensed,
    route_atob,
    route_btoa,
    is_loop,
    is_highlighted,
    steps,
    photos,
    medias,
    theme_primary_color,
    theme_secondary_color,
    publication_status,
    partners,
    traces,
    related_rideset,
    updated,
    vertical_gain,
    ...props
  }: RideData,
  backendUrl = _backendUrl,
): Ride {
  return new Ride(
    id || -1,
    creator ? { id: creator.id || undefined, username: creator.username || undefined } : undefined,
    title || '',
    description || null,
    distance || 0,
    route_duration || route_duration === 0
      ? Math.max(route_duration - (route_duration % 900), 900)
      : undefined,
    duration || 0,
    vertical_gain || undefined,
    difficulty ? difficulties[difficulty as TBackendRideDifficulty] : undefined,
    public_kind ? publicKinds[public_kind as TBackendRidePublicKind] : undefined,
    area_wide ? areas[area_wide as TBackendRideArea] : undefined,
    (themes || []).reduce<number[]>((res, themeId) => {
      if (themeId) res.push(themeId);

      return res;
    }, []),
    icon ? `${backendUrl}${icon}` : null,
    geo_point_a
      ? new Place(
          undefined,
          geo_point_a as GeoJSON.Point,
          geo_point_a_title ? { primaryText: geo_point_a_title } : undefined,
        )
      : geo_point_a,
    geo_point_b
      ? new Place(
          undefined,
          geo_point_b as GeoJSON.Point,
          geo_point_b_title ? { primaryText: geo_point_b_title } : undefined,
        )
      : geo_point_b,
    geo_point_center as GeoJSON.Point | null | undefined,
    geometry_condensed as GeoJSON.MultiLineString | null | undefined,
    null,
    route_atob,
    route_btoa,
    Boolean(is_loop),
    Boolean(is_highlighted),
    (steps || [])
      .reduce<IRideStep[]>((res, data) => {
        if (data) res.push(parseRideStep(data, backendUrl));

        return res;
      }, [])
      .sort((a, b) => (a.order && b.order ? a.order - b.order : 0)),
    [],
    (photos || [])
      .reduce<IPhoto[]>((res, photo) => {
        if (photo) res.push(parsePhoto(photo, backendUrl));

        return res;
      }, [])
      .sort((a, b) => (a.id && b.id ? a.id - b.id : 0)),
    (medias || [])
      .reduce<IRideMedia[]>((res, data) => {
        if (data) res.push(parseRideMedia(data));

        return res;
      }, [])
      .sort((a, b) => (a.id && b.id ? a.id - b.id : 0)),
    theme_primary_color,
    theme_secondary_color,
    publication_status
      ? publicationStatuses[publication_status as TBackendPublicationStatus]
      : undefined,
    (partners || []).reduce<
      Array<Partial<{ code: string; icon: string; id: number; title: string }>>
    >((res, data) => {
      if (data)
        res.push({
          code: data.code || undefined,
          icon: data.icon
            ? data.icon.indexOf('http') === -1
              ? `${environment.backendUrl}${data.icon}`
              : data.icon
            : undefined,
          id: data.id || undefined,
          title: data.title || undefined,
        });

      return res;
    }, []),
    (traces || []).reduce<number[]>((res, traceId) => {
      if (traceId) res.push(traceId);

      return res;
    }, []),
    related_rideset
      ? related_rideset.reduce<Array<{ id: number }>>((res, rideset) => {
          if (rideset?.rideset) res.push({ id: rideset.rideset });

          return res;
        }, [])
      : undefined,
    related_rideset?.[0]?.ride_atob_previous || undefined,
    related_rideset?.[0]?.ride_atob_next || undefined,
    moment(updated),
    'created' in props && props.created ? moment(props.created) : undefined,
    'privacy' in props && props.privacy
      ? props.privacy === 'FORCE_PRIVATE'
        ? 'forcePrivate'
        : props.privacy === 'PUBLIC'
          ? 'public'
          : 'private'
      : undefined,
    ('bike_type' in props && (props.bike_type === 'ELECTRIC' ? 'hybridBike' : 'traditional')) ||
      undefined,
    'profile' in props && props.profile
      ? backendCyclingProfiles[props.profile as TBackendCyclingProfile]
      : undefined,
  );
}

function parseRideStep(
  {
    id,
    order,
    title,
    description,
    geo_point,
    distance_from_geo_point_a: distanceFromStart,
    photos,
    medias,
  }: RideDataSteps,
  backendUrl = _backendUrl,
): IRideStep {
  return {
    id: id || -1,
    order: order || 1,
    title: title || undefined,
    description: description || undefined,
    location: geo_point as GeoJSON.Point | null | undefined,
    distanceFromStart: distanceFromStart || distanceFromStart === 0 ? distanceFromStart : undefined,
    photos: (photos || [])
      .reduce<IPhoto[]>((res, photo) => {
        if (photo) res.push(parsePhoto(photo, backendUrl));

        return res;
      }, [])
      .sort((a, b) => (a.id && b.id ? a.id - b.id : 0)),
    medias: (medias || [])
      .reduce<IRideMedia[]>((res, data) => {
        if (data) res.push(parseRideMedia(data));

        return res;
      }, [])
      .sort((a, b) => (a.id && b.id ? a.id - b.id : 0)),
    poi: null,
  };
}

export function parseRideMedia({
  id,
  title,
  description,
  copyright,
  url,
  type,
}: RideDataMedias): IRideMedia {
  return {
    id: id || -1,
    title,
    description,
    copyright,
    url: url || undefined,
    type: type ? mediaTypes[type as TBackendRideMediaType] : undefined,
  };
}
