import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import {
  AppService,
  AuthService,
  BikeRoute,
  CommutingTrip,
  CommutingTripService,
  Employee,
  EmployeeService,
  Event,
  EventService,
  Geoagglo,
  GeocoderService,
  Geogroup,
  GeogroupService,
  HttpService,
  IBounds,
  IReportTypesMapValue,
  IUserGameLevel,
  Place,
  PoiCategory,
  ReportSource,
  ReportType,
  Ride,
  RideService,
  RideTheme,
  Route,
  RouteService,
  TBaseLayer,
  TPoiCategoryCode,
  TReportTypeCode,
  Trip,
  TripService,
  User,
  UserGeogroup,
  UserPlace,
  UserRide,
  UserService,
  reportTypesMap as _reportTypesMap,
  countryCodes,
  poiCategoryCodes,
  useCancellablePromise,
  useTracker,
} from '@geovelo-frontends/commons';
import { SvgIconProps } from '@mui/material';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { graphql, useStaticQuery } from 'gatsby';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { clearQueryParam } from 'react-use-query-param-string';

import { ContextQuery, PoiCategoryData } from '../graphql-types';

import { HideMapIcon } from './components/icons';
import environment from './environment';
import { THeatmapData, getBiggerRing } from './hooks/map/heatmap';
import useQueryParams from './hooks/query-params';
import { parseGeoagglo } from './utils/geoagglo';
import { parsePoiCategories } from './utils/poi-category';
import { parseReportSource } from './utils/report-source';
import { parseReportType } from './utils/report-type';
import { parseRideTheme } from './utils/ride-theme';

import { LngLatBoundsLike, Map, PaddingOptions } from '!maplibre-gl';

Chart.register(
  BarController,
  BarElement,
  LineController,
  PointElement,
  LineElement,
  LinearScale,
  TimeScale,
  CategoryScale,
  Tooltip,
  Legend,
  Filler,
);

Chart.defaults.font.family = '"Nunito Sans", "Roboto", sans-serif';

type TSmallDeviceShowDetailsAction = {
  labelKey: string;
  Icon: (props: SvgIconProps) => JSX.Element;
};

type TSmallDeviceRedirectAction = {
  callback: () => void;
  labelKey: string;
};

type TAppContext = {
  hasHeaderDarkBackground: boolean;
  headerBackgroundColor?: string;
  highlightedEvent?: Event | null;
  isDetailsView: boolean;
  siteUrl?: string | null;
  smallDeviceContentShowed: boolean;
  smallDeviceRedirectAction?: TSmallDeviceRedirectAction;
  smallDeviceShowDetailsAction: TSmallDeviceShowDetailsAction;
  mode: 'light' | 'dark';
};

type TMapContext = {
  baseLayer: TBaseLayer;
  bounds?: IBounds;
  current: Map | null;
  facilitiesShowed: boolean;
  heatmapShowed: boolean;
  hillshadesShowed: boolean;
  initialBounds?: LngLatBoundsLike;
  layersDrawerOpen: boolean;
  parkingSpacesShowed: boolean;
  reportsShowed: boolean;
  zoom?: number;
};

type TGeoaggloContext = {
  current: Geoagglo | null;
  all?: Geoagglo[];
  cities?: Geoagglo[];
  countries?: Geoagglo[];
};

type TPoiContext = {
  categories?: PoiCategory[];
  selectedCategories: { [key in TPoiCategoryCode]?: boolean };
};

export type TReportTypesMap = {
  [key in TReportTypeCode]?: IReportTypesMapValue & { titleKey: string };
};

export type TReportSourcesMap = {
  [key: number]: ReportSource;
};

type TReportContext = {
  newReportDialogOpen: boolean;
  sourcesMap?: TReportSourcesMap;
  types?: ReportType[];
  typesMap?: TReportTypesMap;
};

type TUserContext = {
  allTimeHeatmapData?: THeatmapData;
  current?: User | null;
  commutingTrips?: CommutingTrip[];
  employee?: Employee | null;
  gameLevel?: IUserGameLevel;
  geogroups?: UserGeogroup[];
  geolocation?: Place;
  loadingAllTimeHeatmapData: boolean;
  places?: UserPlace[];
  routes?: Route[];
  rides?: UserRide[];
  signingOut: boolean;
  signingOutFailed?: boolean;
  trips?: Trip[];
};

type TGeogroupContext = {
  list?: Geogroup[];
};

type TRideContext = {
  themes?: RideTheme[];
  map: { [key: number]: Ride };
  bikeRoutes?: BikeRoute[];
};

type TActions = {
  getGeogroups: () => void;
  getUserAllTimeHeatmapData: () => void;
  getUserRides: () => void;
  getUserRoutes: () => void;
  getUserTrips: () => void;
  getUserGeogroups: () => Promise<void>;
  openMapLayersDrawer: (open: boolean) => void;
  openNewReportDialog: (open: boolean) => void;
  selectPoiCategories: (categories: { [key in TPoiCategoryCode]?: boolean }) => void;
  setCurrentGeoagglo: (geoagglo: Geoagglo | null) => void;
  setCurrentUser: (user?: User | null) => void;
  setHasHeaderDarkBackground: (dark: boolean) => void;
  setHeaderBackgroundColor: (color?: string) => void;
  setIsDetailsView: (isDetails: boolean) => void;
  setMap: (map: Map | null) => void;
  setMapBaseLayer: (layer: TBaseLayer) => void;
  setMapBounds: (bounds?: IBounds) => void;
  setMapInitialBounds: (bounds: LngLatBoundsLike) => void;
  setMapZoom: (zoom?: number) => void;
  setRidesMap: (map: { [key: number]: Ride }) => void;
  setSmallDeviceShowDetailsAction: (action: TSmallDeviceShowDetailsAction) => void;
  setSmallDeviceRedirectAction: (action?: TSmallDeviceRedirectAction) => void;
  setUserPlaces: (places?: UserPlace[]) => void;
  setUserRides: (rides?: UserRide[]) => void;
  setUserRoutes: (routes?: Route[]) => void;
  setUserTrips: (trips?: Trip[]) => void;
  setUserGeogroups: (geogroups?: UserGeogroup[]) => void;
  showSmallDeviceContent: (showed: boolean) => void;
  signOut: () => void;
  toggleFacilitiesOnMap: (showed: boolean) => void;
  toggleHeatmapOnMap: (showed: boolean) => void;
  toggleHillshadesOnMap: (showed: boolean) => void;
  toggleParkingSpacesOnMap: (showed: boolean) => void;
  toggleReportsOnMap: (showed: boolean) => void;
  setMode: (mode: 'light' | 'dark') => void;
};

type TContext = {
  actions: TActions;
  app: TAppContext;
  geoagglo: TGeoaggloContext;
  geogroup: TGeogroupContext;
  map: TMapContext;
  poi: TPoiContext;
  report: TReportContext;
  ride: TRideContext;
  user: TUserContext;
};

export const mapPadding: PaddingOptions = { top: 64, right: 70, bottom: 78, left: 20 };

export const defaultSmallDeviceShowDetailsAction = {
  labelKey: 'commons.actions.hide_map',
  Icon: HideMapIcon,
};

export const AppContext = createContext<TContext>({
  app: {
    hasHeaderDarkBackground: false,
    isDetailsView: false,
    smallDeviceContentShowed: false,
    smallDeviceShowDetailsAction: defaultSmallDeviceShowDetailsAction,
    mode: 'light',
  },
  map: {
    current: null,
    baseLayer: 'geovelo',
    parkingSpacesShowed: true,
    reportsShowed: true,
    facilitiesShowed: false,
    hillshadesShowed: false,
    heatmapShowed: false,
    layersDrawerOpen: false,
  },
  geoagglo: { current: null },
  poi: { selectedCategories: {} },
  report: { newReportDialogOpen: false },
  user: { signingOut: false, loadingAllTimeHeatmapData: false },
  geogroup: {},
  ride: { map: {} },
  actions: {
    setHasHeaderDarkBackground: () => undefined,
    setHeaderBackgroundColor: () => undefined,
    setIsDetailsView: () => undefined,
    showSmallDeviceContent: () => undefined,
    setSmallDeviceShowDetailsAction: () => undefined,
    setSmallDeviceRedirectAction: () => undefined,
    setMap: () => undefined,
    setMapInitialBounds: () => undefined,
    setMapBounds: () => undefined,
    setMapZoom: () => undefined,
    setMapBaseLayer: () => undefined,
    toggleParkingSpacesOnMap: () => undefined,
    toggleReportsOnMap: () => undefined,
    toggleFacilitiesOnMap: () => undefined,
    toggleHeatmapOnMap: () => undefined,
    toggleHillshadesOnMap: () => undefined,
    openMapLayersDrawer: () => undefined,
    setCurrentGeoagglo: () => undefined,
    selectPoiCategories: () => undefined,
    openNewReportDialog: () => undefined,
    setCurrentUser: () => undefined,
    setUserPlaces: () => undefined,
    getUserRoutes: () => undefined,
    setUserRoutes: () => undefined,
    getUserRides: () => undefined,
    setUserRides: () => undefined,
    getUserTrips: () => undefined,
    setUserTrips: () => undefined,
    getUserGeogroups: async () => await undefined,
    setUserGeogroups: () => undefined,
    signOut: () => undefined,
    getGeogroups: () => undefined,
    setRidesMap: () => undefined,
    setMode: () => undefined,
    getUserAllTimeHeatmapData: () => undefined,
  },
});

function ContextProvider({ children }: { children: JSX.Element }): JSX.Element {
  const { site, allPoiCategory, allReportType, allReportSource, allRideTheme, allGeoagglo } =
    useStaticQuery<ContextQuery>(graphql`
      query Context {
        site {
          siteMetadata {
            siteUrl
          }
        }
        allPoiCategory {
          nodes {
            data {
              code
              description
              id
              parent
              title
            }
          }
        }
        allReportType {
          nodes {
            data {
              can_comment
              can_rate
              code
              description
              id
              is_bind_to_osm
              is_public
              need_date
              need_description
              need_location
              need_photo
              order
              title
            }
          }
        }
        allReportSource {
          nodes {
            originalId
            data {
              code
              id
              title
              url
            }
          }
        }
        allRideTheme {
          nodes {
            data {
              created
              description
              id
              title
              updated
            }
          }
        }
        allGeoagglo {
          nodes {
            data {
              area
              bounds
              code
              geo_center {
                coordinates
                type
              }
              has_bss
              has_cyclability
              has_public_transport
              has_rides
              id
              importance
              logo
              logo_alt
              long_name
              order
              photo
              short_name
            }
          }
        }
      }
    `);

  const [initialized, setInitialized] = useState(typeof window === 'undefined');
  const { trackPageView, trackEvent } = useTracker();
  const { get: getQueryParams } = useQueryParams(
    typeof window !== 'undefined' ? window.location.search : '',
  );

  // app context
  const [hasHeaderDarkBackground, setHasHeaderDarkBackground] = useState(false);
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState<string>();
  const [isDetailsView, setIsDetailsView] = useState(false);
  const [smallDeviceContentShowed, showSmallDeviceContent] = useState(false);
  const [smallDeviceShowDetailsAction, setSmallDeviceShowDetailsAction] = useState(
    defaultSmallDeviceShowDetailsAction,
  );
  const [smallDeviceRedirectAction, setSmallDeviceRedirectAction] =
    useState<TSmallDeviceRedirectAction>();
  const [highlightedEvent, setHighlightedEvent] = useState<Event>();
  const [mode, setMode] = useState<'light' | 'dark'>('light');

  // map context
  const [map, setMap] = useState<Map | null>(null);
  const [mapInitialBounds, setMapInitialBounds] = useState<LngLatBoundsLike>();
  const [mapBounds, setMapBounds] = useState<IBounds>();
  const [mapZoom, setMapZoom] = useState<number>();
  const [mapBaseLayer, setMapBaseLayer] = useState<TBaseLayer>('geovelo');
  const [facilitiesShowedOnMap, toggleFacilitiesOnMap] = useState(() => {
    let facilitiesItem: string | null = null;
    try {
      if (typeof window !== 'undefined')
        facilitiesItem = localStorage.getItem('display_facilities');
    } catch {
      console.error('localStorage access is denied');
    }

    return facilitiesItem !== 'false';
  });
  const [hillshadesShowedOnMap, toggleHillshadesOnMap] = useState(() => {
    let hillshadesItem: string | null = null;
    try {
      if (typeof window !== 'undefined')
        hillshadesItem = localStorage.getItem('display_hillshades');
    } catch {
      console.error('localStorage access is denied');
    }

    return hillshadesItem === 'true';
  });
  const [heatmapShowedOnMap, toggleHeatmapOnMap] = useState(false);
  const [parkingSpacesShowedOnMap, toggleParkingSpacesOnMap] = useState<boolean>(() => {
    let parkingSpacesItem: string | null = null;
    try {
      if (typeof window !== 'undefined')
        parkingSpacesItem = localStorage.getItem('display_parking_spaces');
    } catch {
      console.error('localStorage access is denied');
    }

    return parkingSpacesItem === 'true';
  });
  const [reportsShowedOnMap, toggleReportsOnMap] = useState<boolean>(() => {
    let reportsItem: string | null = null;
    try {
      if (typeof window !== 'undefined') reportsItem = localStorage.getItem('display_reports');
    } catch {
      console.error('localStorage access is denied');
    }

    return reportsItem === 'true';
  });
  const [mapLayersDrawerOpen, openMapLayersDrawer] = useState(false);

  // geoagglo context
  const [geoagglos] = useState(
    allGeoagglo.nodes.reduce<Geoagglo[]>((res, { data }) => {
      if (data) res.push(parseGeoagglo(data));

      return res;
    }, []),
  );
  const [currentGeoagglo, setCurrentGeoagglo] = useState<Geoagglo | null>(null);

  // report context
  const [newReportDialogOpen, openNewReportDialog] = useState(false);
  const [reportTypes] = useState(
    allReportType.nodes.reduce<ReportType[]>((res, { data }) => {
      if (data) {
        const type = parseReportType(data);
        if (type) res.push(type);
      }

      return res;
    }, []),
  );
  const [reportTypesMap] = useState<TReportTypesMap>(
    reportTypes.reduce<TReportTypesMap>((res, { code, titleKey }) => {
      if (code !== 'support' && code !== 'exclusionZone')
        res[code] = { ..._reportTypesMap[code], titleKey };
      return res;
    }, {}),
  );
  const [reportSourcesMap] = useState<TReportSourcesMap>(
    allReportSource.nodes.reduce<TReportSourcesMap>((res, { data }) => {
      if (data?.id) {
        const source = parseReportSource(data);
        if (source) res[data.id] = source;
      }

      return res;
    }, {}),
  );

  // poi context
  const [poiCategories] = useState(
    parsePoiCategories(
      allPoiCategory.nodes.reduce<PoiCategoryData[]>((res, { data }) => {
        if (data) res.push(data);

        return res;
      }, []),
    ),
  );
  const [selectedPoiCategories, selectPoiCategories] = useState<{
    [key in TPoiCategoryCode]?: boolean;
  }>(() => {
    const _selectedCategories: { [key in TPoiCategoryCode]?: boolean } = {};
    let item: string | null = null;
    try {
      if (typeof window !== 'undefined') item = localStorage.getItem('poi_categories');
    } catch {
      console.error('localStorage access is denied');
    }
    const _poiCategoryCodes = item ? item.split(',') : [];

    poiCategoryCodes.forEach((code) => {
      if (_poiCategoryCodes.indexOf(code) > -1)
        _selectedCategories[code as TPoiCategoryCode] = true;
    });

    return _selectedCategories;
  });

  // user context
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [userGeolocation, setUserGeolocation] = useState<Place>();
  const [userPlaces, setUserPlaces] = useState<UserPlace[]>();
  const [userRoutes, setUserRoutes] = useState<Route[]>();
  const [userRides, setUserRides] = useState<UserRide[]>();
  const [userTrips, setUserTrips] = useState<Trip[]>();
  const [userGeogroups, setUserGeogroups] = useState<UserGeogroup[]>();
  const [userEmployee, setUserEmployee] = useState<Employee | null>();
  const [userCommutingTrips, setUserCommutingTrips] = useState<CommutingTrip[]>();
  const [userGameLevel, setUserGameLevel] = useState<IUserGameLevel>();
  const [signingOut, setSigningOut] = useState(false);
  const [signingOutFailed, setSigningOutFailed] = useState<boolean>();
  const [allTimeHeatmapData, setAllTimeHeatmapData] = useState<THeatmapData>();
  const [loadingAllTimeHeatmapData, setLoadingAllTimeHeatmapData] = useState(false);
  const loadingAllTimeSimplifiedTracesRef = useRef(false);
  const { cancellablePromise: cancellableUserPromise, cancelPromises: cancelUserPromises } =
    useCancellablePromise();

  // geogroup context
  const [geogroups, setGeogroups] = useState<Geogroup[]>();

  // ride context
  const [rideThemes] = useState(
    allRideTheme.nodes.reduce<RideTheme[]>((res, { data }) => {
      if (data) {
        const theme = parseRideTheme(data);
        if (theme) res.push(theme);
      }

      return res;
    }, []),
  );
  const [ridesMap, setRidesMap] = useState<{ [key: number]: Ride }>({});

  useEffect(() => {
    init();

    let watchPositionId: number;
    if ('geolocation' in navigator) {
      let id: number;
      watchPositionId = navigator.geolocation.watchPosition(
        async ({ coords: { latitude: lat, longitude: lng } }) => {
          id = new Date().getTime();
          const point: GeoJSON.Point = { type: 'Point', coordinates: [lng, lat] };

          try {
            const place = await GeocoderService.reverseGeocode(id, point);
            if (place.id === id) {
              place.id = 'geolocation';
              setUserGeolocation(place);
            }
          } catch {
            setUserGeolocation(new Place(id, point));
          }

          navigator.geolocation.clearWatch(watchPositionId);
        },
        (err) => console.error(err),
      );
    }

    return () => {
      navigator.geolocation.clearWatch(watchPositionId);
    };
  }, []);

  useEffect(() => {
    if (!process.env.GATSBY_APP_ENV || process.env.GATSBY_APP_ENV === 'development')
      trackPageView();
  }, [typeof window !== 'undefined' && window.location.pathname]);

  useEffect(() => {
    if (currentUser) {
      getUserPlaces();
      getUserEmployee();
      getUserGameLevel();
    } else {
      setUserPlaces(undefined);
      setUserRoutes(undefined);
      setUserRides(undefined);
      setUserTrips(undefined);
      setUserGeogroups(undefined);
      setUserEmployee(undefined);
      setUserCommutingTrips(undefined);
      setUserGameLevel(undefined);
      setAllTimeHeatmapData(undefined);
    }

    return () => cancelUserPromises();
  }, [currentUser]);

  async function init() {
    AppService.environment = environment;
    const { highlightedEventId } = environment;

    if (highlightedEventId) getHighlightedEvent(highlightedEventId);

    const userId = getQueryParams()['user-id'];
    const authorizationToken = getQueryParams()['auth-token'];
    clearQueryParam('user-id');
    clearQueryParam('auth-token');
    if (userId && authorizationToken) {
      try {
        HttpService.authorizationToken = `Token ${authorizationToken}`;
        localStorage.setItem('authorization_token', `Token ${authorizationToken}`);
        localStorage.setItem('user_id', `${userId}`);
      } catch {
        console.error('localStorage access is denied');
      }
    }
    getCurrentUser();

    setInitialized(true);
  }

  async function getHighlightedEvent(id: number) {
    try {
      const event = await EventService.getEvent(id);

      setHighlightedEvent(event);
    } catch (err) {
      console.error(err);
    }
  }

  async function getCurrentUser() {
    try {
      const user = await UserService.getCurrentUser();
      setCurrentUser(user);
    } catch (err) {
      console.error(err);
    }
  }

  async function getUserPlaces() {
    try {
      const places = await cancellableUserPromise(UserService.getPlaces());
      setUserPlaces(
        places.sort((a, b) => {
          if (a.type === 'home' && b.type !== 'home') return -1;
          if (b.type === 'home' && a.type !== 'home') return 1;
          if (a.type === 'work' && b.type !== 'work') return -1;
          if (b.type === 'work' && a.type !== 'work') return 1;

          return a.created.isBefore(b.created) ? -1 : 1;
        }),
      );
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
      }
    }
  }

  async function getUserEmployee() {
    try {
      const employees = await cancellableUserPromise(EmployeeService.getEmployees());
      const employee = employees[0]?.employee || null;

      setUserEmployee(employee);
      if (employee) await getUserCommutingTrips(employee.id);
      else setUserCommutingTrips([]);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
      }
    }
  }

  async function getUserCommutingTrips(employeeId: number) {
    try {
      const commutingTrips = await cancellableUserPromise(
        CommutingTripService.getCommutingTrips({ employeeId }),
      );

      setUserCommutingTrips(commutingTrips);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        setUserCommutingTrips([]);
      }
    }
  }

  async function getUserGameLevel() {
    try {
      const result = await cancellableUserPromise(UserService.getUserGameLevel());
      setUserGameLevel(result);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
      }
    }
  }

  async function getUserRoutes() {
    try {
      const routes = await RouteService.getRoutes({ types: ['USER'] });
      setUserRoutes(routes);
    } catch (err) {
      console.error(err);
    }
  }

  async function getUserRides() {
    try {
      const _userRides = await UserService.getRides();

      if (_userRides.length > 0) {
        const { rides } = await RideService.getRides({
          page: 1,
          pageSize: _userRides.length,
          ids: _userRides.map(({ rideId }) => rideId),
          query:
            '{id, title, geo_point_a, geo_point_a_title, geo_point_b, geo_point_b_title, geo_point_center, duration, vertical_gain, route_duration, distance, difficulty, icon, photos{thumbnail, thumbnailSquare}}',
        });

        const _ridesMap = rides.reduce<{ [key: number]: Ride }>((res, ride) => {
          if (ride.id) res[ride.id] = ride;

          return res;
        }, {});

        setRidesMap(_ridesMap);
        setUserRides(_userRides.filter(({ rideId }) => _ridesMap[rideId]));
      } else setUserRides([]);
    } catch (err) {
      console.error(err);
    }
  }

  async function getUserTrips() {
    try {
      const trips = await TripService.getUserTrips({
        query: '{id, title, distance, steps{id, itinerary{geometry}}}',
      });
      setUserTrips(trips.sort((a, b) => b.id - a.id));
    } catch (err) {
      console.error(err);
    }
  }

  async function getUserGeogroups() {
    try {
      const geogroups = await GeogroupService.getUserGeogroups();

      setUserGeogroups(geogroups.filter(({ group: { code } }) => code !== 'france') || []);
    } catch (err) {
      console.error(err);
    }
  }

  async function signOut() {
    setSigningOut(true);
    setSigningOutFailed(undefined);

    try {
      await AuthService.signOut();
      trackEvent('Sign out', 'Signed out', 'Profile menu sign out');
      setCurrentUser(null);
    } catch {
      setSigningOutFailed(true);
    }

    setSigningOut(false);
  }

  async function getGeogroups() {
    try {
      const { geogroups } = await GeogroupService.getGeogroups();

      setGeogroups(geogroups);
    } catch (err) {
      console.error(err);
      setGeogroups([]);
    }
  }

  async function getUserAllTimeHeatmapData() {
    if (!currentUser || loadingAllTimeSimplifiedTracesRef.current || allTimeHeatmapData) return;

    setLoadingAllTimeHeatmapData(true);

    try {
      const cells = await UserService.getH3Cells();
      const { isInBiggerRing } = getBiggerRing(cells);

      setAllTimeHeatmapData({ cells, isInBiggerRing });
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
      }
    }

    loadingAllTimeSimplifiedTracesRef.current = false;
    setLoadingAllTimeHeatmapData(false);
  }

  if (!initialized) return <></>;

  return (
    <AppContext.Provider
      value={{
        app: {
          siteUrl: site?.siteMetadata?.siteUrl,
          hasHeaderDarkBackground,
          headerBackgroundColor,
          isDetailsView,
          smallDeviceContentShowed,
          smallDeviceShowDetailsAction,
          smallDeviceRedirectAction,
          highlightedEvent,
          mode,
        },
        map: {
          current: map,
          initialBounds: mapInitialBounds,
          bounds: mapBounds,
          zoom: mapZoom,
          baseLayer: mapBaseLayer,
          parkingSpacesShowed: parkingSpacesShowedOnMap,
          reportsShowed: reportsShowedOnMap,
          facilitiesShowed: facilitiesShowedOnMap,
          hillshadesShowed: hillshadesShowedOnMap,
          heatmapShowed: heatmapShowedOnMap,
          layersDrawerOpen: mapLayersDrawerOpen,
        },
        geoagglo: {
          all: geoagglos,
          cities: geoagglos?.filter(({ code }) => countryCodes.indexOf(code) === -1),
          countries: geoagglos?.filter(({ code }) => countryCodes.indexOf(code) > -1),
          current: currentGeoagglo,
        },
        poi: { categories: poiCategories, selectedCategories: selectedPoiCategories },
        report: {
          newReportDialogOpen,
          types: reportTypes,
          typesMap: reportTypesMap,
          sourcesMap: reportSourcesMap,
        },
        user: {
          current: currentUser,
          places: userPlaces,
          routes: userRoutes,
          rides: userRides,
          trips: userTrips,
          geogroups: userGeogroups,
          geolocation: userGeolocation,
          employee: userEmployee,
          commutingTrips: userCommutingTrips,
          gameLevel: userGameLevel,
          signingOut,
          signingOutFailed,
          allTimeHeatmapData,
          loadingAllTimeHeatmapData,
        },
        geogroup: {
          list: geogroups,
        },
        ride: { themes: rideThemes, map: ridesMap },
        actions: {
          setHasHeaderDarkBackground,
          setHeaderBackgroundColor,
          setIsDetailsView,
          showSmallDeviceContent,
          setSmallDeviceShowDetailsAction,
          setSmallDeviceRedirectAction,
          setMap,
          setMapInitialBounds,
          setMapBounds,
          setMapZoom,
          setMapBaseLayer,
          toggleParkingSpacesOnMap,
          toggleReportsOnMap,
          toggleFacilitiesOnMap,
          toggleHeatmapOnMap,
          toggleHillshadesOnMap,
          openMapLayersDrawer,
          setCurrentGeoagglo,
          selectPoiCategories,
          openNewReportDialog,
          setCurrentUser,
          setUserPlaces,
          getUserRoutes,
          setUserRoutes,
          getUserRides,
          setUserRides,
          getUserTrips,
          setUserTrips,
          getUserGeogroups,
          setUserGeogroups,
          signOut,
          getGeogroups,
          setRidesMap,
          setMode,
          getUserAllTimeHeatmapData,
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default ContextProvider;
